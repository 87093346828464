.btn-ghost {
  outline-color: transparent;
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  color: white;
  transition: 0.5s all;
}

.btn-ghost:hover {
  color: var(--primaryColor);
}
